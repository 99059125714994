import { Component, OnInit } from '@angular/core';
import { GetstudentsService } from '../getstudents.service';
import { ActivatedRoute } from '@angular/router';

export interface Student{
   name:string;
  intro: string;
  images: string[];
  image_captions: string[];
  instagram: string;
  facebook: string;
  link:string;
  long_text:string;
  embed_srcs: string[];
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private getstudentsService: GetstudentsService,private route: ActivatedRoute) { }
  students: Student[];
  ngOnInit(): void {
    
  	this.getstudentsService.getData()
	.subscribe(
		(data: Student[]) => this.students = data ,err => console.error(err) , () => this.check() );

  }
  check(){
  	console.log(this.students);
  }
}
