
<nav *ngIf="!isHome" [style.font-size.vw]="navFontSize">

	<a routerLink="/show" routerLinkActive="active">HOME</a><br>
	<a routerLink="/about" routerLinkActive="active">ABOUT</a>

</nav>
<div id="container"  #containerRef>
	<!-- <canvas  #canvas width="100%" height="100%"></canvas> -->
	<!-- <button (click)="play()">Play</button>    -->
	<router-outlet></router-outlet>
	<!-- <img style="width:20%;margin-top: 5% " src="./assets/images/logos/newcastle.jpg" alt="newcastle university logo"  (click)="checkHeight()" /> -->

</div>