import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetstudentsService {

  constructor(private http: HttpClient) { }

  dataUrl='assets/data/work.json';
	getData() {
		return this.http.get(this.dataUrl);
	}
}
