//ng build --base-href=/shows/2020/

import { Component, ViewChild, ElementRef , OnInit,AfterViewInit,AfterContentChecked, AfterViewChecked, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IsMobileService } from './is-mobile.service';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';

export class Work {
  title:string;
  date:string;
  fnames:string[];
  categories:string[];
  themes:string[];
  methods:string[];
  code:string;
  vimeo_code:string;
  has_video:string;
  description:string;
  research:string;
}

  

export class Square {
  private color = 'grey';
  private x = 0;
  private y = 0;
  private z = 3;

  constructor(private ctx: CanvasRenderingContext2D, private _x: number, private _y: number,  private _size: number) {
  	this.x=_x;
  	this.y=_y;
  	this.z = _size;
  }

  moveRight() {
    this.x++;
    if(this.x>this.ctx.canvas.width)this.x=0;
    this.draw();
  }
  moveDown() {
    this.y++;
    if(this.y>this.ctx.canvas.height)this.y=0;
    this.draw();
  }

  private draw() {
    this.ctx.fillStyle = this.color;
    this.ctx.fillRect(this.z * this.x, this.z * this.y, this.z, this.z);
  }
}

// @Component({
//   selector: 'app-root',
//   template: `
//     <canvas #canvas width="600" height="300"></canvas>
//     <button (click)="play()">Play</button>
//   `,
//   styles: ['canvas { border-style: solid }']
// })
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit , AfterContentChecked, AfterViewChecked{
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('containerRef') elementView: ElementRef;


  ctx: CanvasRenderingContext2D;
  requestId;
  interval;
  squares: Square[] = [];
  isHome: boolean = false;
  navFontSize: string=  "2";
  containerHeight: number = 3000;
  pHeight: number = 0;

  constructor( router:Router,private ngZone: NgZone, private route: ActivatedRoute, private isMobileService: IsMobileService) {
  	router.events.forEach((event) => {
    if(event instanceof NavigationEnd) {
    	console.log("start nav");
    // 	  this.ctx = this.canvas.nativeElement.getContext('2d');
    // this.ctx.fillStyle = 'red';
    // this.ngZone.runOutsideAngular(() => this.tick());
    // setInterval(() => {
    //   this.tick();
    // }, 10);
    // this.play();
    }
    // NavigationEnd
    // NavigationCancel
    // NavigationError
    // RoutesRecognized
  });
  }

   ngAfterContentChecked(){
   	//console.log("content checked");
   }
   ngAfterViewChecked(){
   	 this.containerHeight = this.elementView.nativeElement.offsetHeight;
     //console.log("this.containerHeight check",this.containerHeight);
       // this.ctx.canvas.height = this.containerHeight;

   }
   ngAfterViewInit() {
   
    
    
  }
  createCanvas(){
  	 this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.fillStyle = 'red';
    this.ngZone.runOutsideAngular(() => this.tick());
    setInterval(() => {
      this.tick();
    }, 10);
    this.play();

  }
  checkHeight(){
  	this.containerHeight = this.elementView.nativeElement.offsetHeight;
     console.log("this.containerHeight",this.containerHeight);
  	console.log("height");
  }
  ngOnInit() {
  	
  	var isMobile = this.isMobileService.getIsMobile();
  	
  	if(isMobile){
      // this.boxWidth= "90%";
      this.navFontSize="6";
    } 
   

  	this.route.params.subscribe(params => {
      console.log("params",params);

    });
  

    // setInterval(() => {
    //   if(this.elementView.nativeElement.offsetHeight!=this.pHeight){
    //   	this.pHeight = this.elementView.nativeElement.offsetHeight;
    //   	clearInterval(this.interval);
    // 	cancelAnimationFrame(this.requestId);
    // 	this.squares = [];
    //   	this.createCanvas();
    //   	console.log("height change",this.pHeight);
    //   }
    // }, 100);
    
   
   
  }
getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
  tick() {
    // 	this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
     this.ctx.fillStyle = "rgba(0,0,0,0.01)";
    this.ctx.fillRect(0,0,this.ctx.canvas.width, this.ctx.canvas.height);
    this.squares.forEach((square: Square) => {
      square.moveRight();
      square.moveDown();
    });
    this.requestId = requestAnimationFrame(() => this.tick);
  }

  play() {
  	 this.ctx.canvas.width  = window.innerWidth;
  	this.ctx.canvas.height = this.elementView.nativeElement.offsetHeight;
 	console.log("play",this.ctx.canvas.width, this.ctx.canvas.height);
  	var numSquares = 0;
  	while(numSquares<80){

  		var xPos = this.getRandomInt(this.ctx.canvas.width);
  		var yPos = this.getRandomInt(this.ctx.canvas.height);
		var size = this.getRandomInt(5);
  		//console.log(xPos,yPos);
  		  const square = new Square(this.ctx,xPos,yPos, size);
    this.squares = this.squares.concat(square);
    numSquares++;
  	}
  
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    cancelAnimationFrame(this.requestId);
  }
}

// import { Component, ViewChild, ElementRef, OnInit, NgZone } from '@angular/core';
// export class Square {
// 	private color = 'red';
// 	private x = 0;
// 	private y = 0;
// 	private z = 30;

// 	constructor(private ngZone: NgZone) {}

// 	moveRight() {
// 		this.x++;
// 		this.draw();
// 	}

// 	private draw() {
// 		this.ctx.fillStyle = this.color;
// 		this.ctx.fillRect(this.z * this.x, this.z * this.y, this.z, this.z);
// 	}
// }

// @Component({
// 	selector: 'app-root',
// 	templateUrl: './app.component.html',
// 	styleUrls: ['./app.component.scss']
// })

// export class AppComponent implements OnInit{
// 	ctx: CanvasRenderingContext2D;
// 	requestId;
// 	interval;
// 	squares: Square[] = [];
// 	title = 'capshow';

// 	ngOnInit(): void {
// 		this.ctx = this.canvas.nativeElement.getContext('2d');
// 		this.ctx.fillStyle = 'red';
// 		this.ngZone.runOutsideAngular(() => this.tick());
// 		setInterval(() => {
// 			this.tick();
// 		}, 200);
// 	}

// 	tick() {
//     this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
//     this.squares.forEach((square: Square) => {
//       square.moveRight();
//     });
//     this.requestId = requestAnimationFrame(() => this.tick);
//   }

//   play() {
//     const square = new Square(this.ctx);
//     this.squares = this.squares.concat(square);
//   }
//   ngOnDestroy() {
//     clearInterval(this.interval);
//     cancelAnimationFrame(this.requestId);
//   }

// }
