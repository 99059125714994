<h1>{{work.title}}</h1>
<!-- <p>See the work <a href="{{student.link}}" > here</a></p> -->
<div class="slider_box">
<!-- <ng-image-slider  [images]="imageObject" #nav></ng-image-slider>

</div> -->
<ng-image-slider 
    [imageSize]="sizeObject" 
   	[infinite] = "true"
    [manageImageRatio]="true"
    [images]="imageObject" #nav></ng-image-slider>

</div>

<p [innerHTML]="work.description"></p>
<iframe  width="100%" height= "500px" [src]="'https://player.vimeo.com/video/'+work.vimeo_code | safe"></iframe>
