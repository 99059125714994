import { Component, OnInit, ElementRef } from '@angular/core';
import { GetstudentsService } from '../getstudents.service';
import { IsMobileService } from '../is-mobile.service';

export interface Student{
	name:string;
	intro: string;
	images: string[];
	image_captions: string[];
	instagram: string;
	facebook: string;
  link:string;
  long_text:string;
  embed_srcs: string[];
}

export class Work {
  title:string;
  date:string;
  fnames:string[];
  categories:string[];
  themes:string[];
  methods:string[];
  code:string;
  vimeo_code:string;
  has_video:string;
  description:string;
  research:string;
}


@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
    
  constructor(private getstudentsService: GetstudentsService, private isMobileService: IsMobileService) { }
  
  students: Student[];
  works: Work[];
  boxWidth : String ="20%";
  titleFontSize: String = "3vw";
  introFontSize: String = "1vw";
  ngOnInit(): void {
 	this.getstudentsService.getData()
	.subscribe(
		(data: Work[]) => this.works = data ,err => console.error(err) , () => this.check() );

  }
  check(){
  	
  	var isMobile = this.isMobileService.getIsMobile();
  	console.log(this.works);

    // array.sort((a,b) => a.date.localeCompare(b.title.rendered));
    this.works.sort((a,b) => b.date.localeCompare(a.date));


  	if(isMobile){
      this.boxWidth= "90%";
      this.titleFontSize = "5vw";
      this.introFontSize = "3vw";
    } 
  	
  }
 
}
