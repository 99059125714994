import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetstudentsService } from '../getstudents.service';
import { IsMobileService } from '../is-mobile.service';


export interface Student{
	 name:string;
  intro: string;
  images: string[];
  image_captions: string[];
  instagram: string;
  facebook: string;
  link:string;
  long_text:string;
  embed_srcs: string[];
}
export class Work {
  title:string;
  date:string;
  fnames:string[];
  categories:string[];
  themes:string[];
  methods:string[];
  code:string;
  vimeo_code:string;
  has_video:string;
  description:string;
  research:string;
}

// @Pipe({ name: 'safe' })

@Component({
	selector: 'app-portfolios',
	templateUrl: './portfolios.component.html',
	styleUrls: ['./portfolios.component.scss']
})
export class PortfoliosComponent implements OnInit {

	constructor(private getstudentsService: GetstudentsService, private route: ActivatedRoute, private isMobileService: IsMobileService) { }
	students: Student[];
   works: Work[];
	work: Work;
	id: String = "";
  sliderImageWidth: String = '95%';
  sliderImageSpace: number = 8;
  sizeObject: object ;
  // url: String = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/843240451&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true";


	imageObject: Array<object> =[];
  // = [{
  //       image: 'assets/images/pic1.jpg',
  //       thumbImage: 'assets/images/pic1.jpg',
  //       alt: 'alt of image',
  //       title: 'image',
  //       imageSize: {width: '100px', height: '20px'} 
  //   }, 
  //   {
  //       image: 'assets/images/pic1.jpg',
  //       thumbImage: 'assets/images/pic1.jpg',
  //       alt: 'alt of image',
  //       title: 'image',
  //        imageSize: {width: '100%', height: '20%'} 
  //   }
  //   ]

  ngOnInit(): void {
    var isMobile = this.isMobileService.getIsMobile();
    if(!isMobile){
      this.sliderImageSpace = 1;
      console.log("not mob");

      this.sizeObject  = { 
        width: '95%',
        height:'300px',
        space: 2
      };
    } else{
      this.sizeObject  = { 
        width: '95%',
       
        space: 8
      };
    }
  	this.route.params.subscribe(params => {
      this.id = params.id;// --> Name must match wanted parameter
      this.getstudentsService.getData()
      .subscribe(
      	(data: Work[]) => this.works = data ,err => console.error(err) , () => this.check() );
  });
  }

  check(){
  	console.log(this.works);
  	for (var i = 0; i < this.works.length; i++) {
  		if(this.id==this.works[i].code){
  			this.work = this.works[i];
        console.log("this student", this.work);
  		}
  	}
  	for (var i = 0; i < this.work.fnames.length; i++) {
  		var obj = {
  			image: 'assets/images/'+this.work.fnames[i],
  			thumbImage: 'assets/images/'+this.work.fnames[i],
  			alt: 'alt of image',
  			
  			imageSize: {width: '100px', height: '20px'} 
  		}

  		this.imageObject.push(obj);
      // var vid ={
      //  video: 'https://youtu.be/6pxRHBw-k8M',
      //  title: 'example title'
      // }
      // this.imageObject.push(vid);
  	}
  	console.log("image ob",this.imageObject);
  }
}
